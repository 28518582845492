import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Grid, Box, Alert, FormControl, Select, MenuItem } from "@mui/material";
import Appbar from "../appbar/Appbar";
import Sidebar from "../sidebar/Sidebar";
import { useFeatures } from "../../context/FeatureContext";
import { useLocation } from "react-router-dom";
import services from "../../services/services";
import userServices from "../../services/userServices";
import styles from "./downloadpage.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import uploadPageImg from "../../assets/uploadpageimg.svg";
import LoadingSpinner from "../../Utils/Loader/LoadingSpinner";
import ErrorCountsTable from "./error-counts-table/ErrorCountsTable";

export default function DownloadFile(props) {
  const { features:{ download_as_csv }, loading: loadingFeatures } = useFeatures()
  const { state } = useLocation();
  const [loader, setLoader] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [fileFormat, setFileFormat] = useState("xlsx");
  const [fileProcessResp, setFileprocess] = useState(null);
  const [responseError, setResponseError] = useState({
    status: "",
    message: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("useEffect");
    fetchData();
  }, []);

  const fetchData = async () => {
    if (state.id) {
      let encodedID = encodeURIComponent(state.id);
      services
        .checkFileProcess(encodedID)
        .then((response) => {
          if (response) {
            setLoader(false);
            setFileprocess(response);
          }
        })
        .catch((err) => {
          setLoader(false);
          setResponseError(err)
        });
    }
  };
  const handleDownload = (fileFormat) => {
    setDownloading(true);
    console.log('FIlE_TYPE', fileFormat)
    services.downloadFileReq(fileProcessResp.id, fileProcessResp.filename, fileFormat).then(res=>{
      setDownloading(false);
    }).catch(err=>{
      alert(err.message);
      setDownloading(false);
    });
  };

  return (
    <Grid
      container
      // display={"flex"}
      // justifyContent={"center"}
      // alignItems={"center"}
    >
      {/* <Sidebar orgId={"Q1234567"} orgName={"Quantiphi"}></Sidebar> */}
      {loader ? (
        <Grid
          item
          container
          md={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            item
            md={4}
            sm={4}
            xs={4}
            flexDirection={"column"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
            height={window.innerHeight - 100}
          >
            <div>
              <CircularProgress style={{ color: "#4b286d" }} />
            </div>
            <div>
              <Typography style={{ wordWrap: "break-word" }}>
                The wait is almost over... just kidding, we're still trying to
                figure out how to operate the scanner...
              </Typography>
            </div>
          </Grid>
        </Grid>
      ) : responseError.status ? (
        <Grid
          item
          md={6}
          m={'auto'}
          mt={'5rem'}
        >
          <Typography>
            Processing took too long. Check back in a while or make sure the
            uploaded file is in a valid format
          </Typography>
        </Grid>
      ) : (
        <Grid
          item
          md={12}
          mt={'3rem'}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
        >
        {responseError.status && (<Alert severity="error">{responseError.message}</Alert>)}
        {downloading && <LoadingSpinner></LoadingSpinner>}
          <img
            src={uploadPageImg}
            alt="uploadPageImg"
            width="15%"
            height="auto"
          />
          <Typography className={styles.h_oneText}>
            Thank you for uploading your spreadsheet!
          </Typography>
          <Typography className={styles.h2Text}>
            If your file does not have any corrections to be made, no need to
            re-upload.{" "}
          </Typography>
          {/* <Typography className={styles.h2Text}>to be downloaded.</Typography> */}
          <Typography className={styles.h2Text}>
            If your file requires correction (cells in red), please make the
            appropriate updates and re-upload.
          </Typography>
          <Typography className={styles.h3Text}>
            Click on download to get the corrected excel file and view
            uncorrected data
          </Typography>
          <Box>
            <Button
              className={[styles.buttoncls, styles.bgtransparent].join(" ")}
              onClick={() => {
                navigate("/");
              }}
            >
              Upload New
            </Button>
            
              {!loadingFeatures && download_as_csv.is_enabled && download_as_csv.roles_allowed.includes(sessionStorage.getItem("user_role")) ? (
                <FormControl variant="standard" className={styles.btnformcontrol} sx={{ m: 1, minWidth: 150 }} size="small">
                <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value="downl"
                label="File Format"
                className={styles.btndropdown}
                disableUnderline="true"
                onChange={(e) => {
                  if(e.target.value) {
                    setFileFormat(e.target.value);
                    handleDownload(e.target.value);
                  }
                  
                }}
                
              >
                <MenuItem value={"downl"} style={{ display: "none" }}>Download</MenuItem>
                <MenuItem value={"xlsx"}>Download as .xlsx</MenuItem>
                <MenuItem value={"csv"}>Download as .csv</MenuItem>
              </Select>
              </FormControl>
              ):(
                <Button
                className={styles.buttoncls}
                onClick={() => {
                  handleDownload();
                }}
              >
                Download
              </Button>
              )}
              
              
            
          </Box>
          {/* </Box> */}
          <ErrorCountsTable counts={fileProcessResp.corrections}/>
        </Grid>
      )}
    </Grid>
  );
}
