import React from "react";
import {Typography, Grid} from "@mui/material";

export default function PageNotFound(props) {
  return (
    <Grid container>
        <Grid
          container
          md={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}>
          <Grid
            item
            md={4}
            sm={4}
            xs={4}
            flexDirection={"column"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
            height={window.innerHeight - 100}
          >
            <div>
              <Typography style={{ wordWrap: "break-word" }}>
                Looks like you have landed on the Wrong Page
              </Typography>
            </div>
          </Grid>
        </Grid>
      
        
      
    </Grid>
  );
}
