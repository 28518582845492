import React from 'react'
import { FeatureProvider } from '../../context/FeatureContext';  
import {Navigate, useLocation} from "react-router-dom"

const ProtectedRoute = ({children}) => {
    let location = useLocation();

    if(!sessionStorage.getItem("ags_token")) {
        return <Navigate to="/login" state={{from: location}} replace />
    }
   
 return <FeatureProvider>{children}</FeatureProvider>

};

export default ProtectedRoute;