import React, { useCallback, useEffect, useState } from "react";
import { throttle } from "lodash";
import {
  Box,
  Grid,
  Button,
  Dialog,
  Typography,
  IconButton,
  TextField,
  FormGroup,
  InputAdornment,
  Alert,
  Checkbox
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MuiPhoneNumber from 'material-ui-phone-number';

import {
  numberPattern,
  EMAIL_VALIDATION_REGEX,
  normalStringRegex,
  passwordStrengthRegEx,
} from "../../Utils/constants";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import styles from "./createUser.module.scss";
import services from "../../services/services";
import userServices from "../../services/userServices";
import LoadingSpinner from "../../Utils/Loader/LoadingSpinner";

export default function MaxWidthDialog(props) {
  const { openModal, closeModal, setRefreshUsersData, userData, setEditSuccess } = props;
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("sm");

  const initialState = {
    first_name: userData ? userData.first_name : null,
    last_name: userData ? userData.last_name : null,
    email: userData ? userData.email : null,
    vendor_id: userData ? userData.vendor_id : null,
    role: userData ? userData.role : null,
    phone_number: userData ? userData.phone_number : null,
    password: null,
    internal: userData ? userData.internal : true,
    looker_access: userData ? userData.looker_access : true,
    updated_at: userData ? userData.updated_at : null,
  };

  const [localState, setLocalState] = useState(initialState);
  const [errorState, setErrorState] = useState({});

  const handleClose = () => {
    closeModal(false);
    setLocalState(initialState);
    setErrorState({});
  };

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [hasUserTouched, setUserTouchedStatus] = useState(false);

  const [responseError, setResponseError] = useState({
    status: "",
    message: "",
  });

  const [vendorSelectOpen, setVendorSelectOpen] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState()
  const [vendorList, setVendorList] = useState([]);
  const [vendorLoading, setVendorLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const keys = {
    first_name: "First Name cannot be empty and should contain only alphabets",
    min_name: " Name cannot be empty and should contain atleast 3 alphabets",
    last_name:
      "Last Name cannot be empty and should contain atleast 3 alphabets",
    vendor:
      "Vendor name cannot be empty and should contain atleast 3 alphabets",

    phone_number: "Phone Number should be valid",
    email: "Enter the valid Email Id in proper format",
    role: "Please select Role",
    password: `Please enter valid password`,
  };

  function captureCredentials(e, type) {
    const { value } = e.target;

    setUserTouchedStatus(true);
    return setLocalState((prevState) => ({
      ...prevState,
      [type]: value.trim(),
    }));
  }

  const handleAuthTypeChange = (event) => {
    setUserTouchedStatus(true);
    // if auth type is internal
    const isInternal = Boolean(event.target.value === "internal");
    if (isInternal) {
      // clear password error state if any
      setErrorState((prevState) => ({
        ...prevState,
        password: {
          error: false,
          errorMessage: "",
        }
      }));
    }
    // set internal to true/false based on radiogroup's string value
    setLocalState((prevState) => ({
      ...prevState,
      password: isInternal ? "" : prevState.password,
      internal: isInternal,
    }));
  };

  const addUser = () => {
    setLoading(true);
    userServices
      .createUser(localState)
      .then((res) => {
        setLoading(false);
        handleClose();
        setRefreshUsersData(true);
      })
      .catch((err) => {
        setLoading(false);
        setResponseError(err);
      });
  };

  const editUser = () => {
    setLoading(true);
    setEditSuccess(false);
    userServices
      .updateUser(localState)
      .then((res) => {
        setLoading(false);
        handleClose();
        setEditSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        setResponseError(err);
      });
  };

  const handleRoleChange = (event) => {
    setUserTouchedStatus(true);
    setLocalState((prevState) => ({
      ...prevState,
      role: event.target.value,
    }));
    // set error message if role unset or clear if role set
    setErrorState((prevState) => ({
      ...prevState,
      role: {
        error: !Boolean(event.target.value),
        errorMessage: Boolean(event.target.value) ? "" : "Please select Role",
      }
    }));
  };

  const handleVendorChange = (event, newValue) => {
    console.log(newValue)
    if(newValue) {
      setUserTouchedStatus(true);
      setSelectedVendor(newValue.label);
      setLocalState((prevState) => ({
        ...prevState,
        vendor_id: newValue?.id,
      }));
      setErrorState((prevState) => ({
        ...prevState,
        vendor: {
          error: false,
          errorMessage: "",
        }
      }));
    } else {
      setSelectedVendor(null)
    }
    
  };

  const throttledSearch = useCallback(
    throttle((value) => {
      setVendorLoading(true);
      services.getVendorSearchResult(value)
        .then(res => {
          setVendorList(res)
        })
        .finally(() => {
          setVendorLoading(false)
        })
    }, 1500), []
  )

  const handleVendorSearch = (e) => {
    if(e && e.target && e.target.value) {
      const input = e.target.value;
      const encodedInput = encodeURIComponent(input)
      throttledSearch(encodedInput);
    }
    
  }

  const handleVendorOpen = () => {
    setVendorSelectOpen(true)
    setVendorLoading(true)
    services.getPreviewVendors()
      .then(res => {
        setVendorList(res)
      })
      .finally(() => {
        setVendorLoading(false)
      })
  }

  const handleVendorClose = () => {
    setVendorSelectOpen(false);
    setVendorList([]);
  }


  function captureFormFields(e, type) {
    const value = e.target.value;
    setUserTouchedStatus(true);
    setLocalState((prevState) => {
      return {
        ...prevState,
        [type]: value,
      };
    });
    //}
  }

  const validateFields = () => {
    return localState.first_name &&
      localState.last_name &&
      localState.email &&
      localState.phone_number &&
      localState.role &&
      localState.vendor_id &&
      localState.internal != null &&
      localState.looker_access != null
      ? false
      : true;
  };

  function validateEmail(value, key) {
    const isEmailValid = EMAIL_VALIDATION_REGEX.test(value);
    if (isEmailValid) {
      setLocalState((prevState) => ({
        ...prevState,
        key: value.trim(),
      }));

      if (value.split("@")[0].length > 50) {
        let temp = Object.assign(errorState);
        temp[key] = {
          error: true,
          errorMessage: "Email ID should not be greater than 50 Character",
        };
        setErrorState(temp);
        return;
      }

      let temp = Object.assign(errorState);
      temp[key] = {
        error: false,
        errorMessage: "",
      };
      setErrorState(temp);
      return;
    }

    let temp = Object.assign(errorState);
    temp[key] = {
      error: true,
      errorMessage: keys[key],
    };
    setErrorState(temp);
  }

  function validateContactNo(value, key) {
    const isNumberValid = numberPattern.test(value);

    if (isNumberValid) {
      setLocalState((prevState) => ({
        ...prevState,
        key: value.trim(),
      }));

      let temp = Object.assign(errorState);
      temp[key] = {
        error: false,
        errorMessage: "",
      };
      setErrorState(temp);
      return;
    }

    let temp = Object.assign(errorState);
    temp[key] = {
      error: true,
      errorMessage: keys[key],
    };
    setErrorState(temp);

    return true;
  }

  function validateString(value, key) {
    const isStringValid = normalStringRegex.test(value.trim());
    if (isStringValid) {
      let temp = Object.assign(errorState);
      temp[key] = {
        error: false,
        errorMessage: "",
      };
      setErrorState(temp);
      return;
    } else {
      let temp = Object.assign(errorState);
      temp[key] = {
        error: true,
        errorMessage: keys[key],
      };
      setErrorState(temp);

      return;
    }
  }

  function passwordValidator(input, key) {
    if (
      input.length < 8 ||
      input.length > 12 ||
      !passwordStrengthRegEx.test(input)
    ) {
      let temp = Object.assign(errorState);
      temp[key] = {
        error: true,
        errorMessage: keys[key],
      };
      setErrorState(temp);
      return true;
    } else {
      let temp = Object.assign(errorState);
      temp[key] = {
        error: false,
        errorMessage: "",
      };
      setErrorState(temp);
      return false;
    }
  }

  const getErrorState = () => {
    for (const key in errorState) {
      if (errorState[key].error) {
        return true;
      }
    }
    return false;
  };

  const getDropDownList = () => {
    let result = [];
    vendorList.forEach((item) => {
      result.push({
        id: item.id,
        label: item.country ? item.name + " - " + item.country : item.name,
      });
    });
    return result;
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openModal}
        disableScrollLock
        onClose={handleClose}
      >
        {loading && <LoadingSpinner></LoadingSpinner>}
        {responseError.status && (
          <Alert severity="error">{responseError.message}</Alert>
        )}
        <DialogTitle>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            flexDirection={"row"}
          >
            <Typography> {userData ? "Update User" : "Create User"}</Typography>
            
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <Grid container>
            <Grid item md={12} width={"100%"} display={"flex"} flexDirection={"row"}>
              <Grid item md={6} width={"100%"}>
                <FormGroup>
                  <Typography className={styles.lableText}>
                    First Name*
                  </Typography>
                  <TextField
                    type="text"
                    name="firstname"
                    placeholder="Enter First Name"
                    className={styles.loginInput}
                    value={localState["first_name"]}
                    onChange={(e) => {
                      captureCredentials(e, "first_name");
                      validateString(e.target.value, "first_name");
                    }}
                    error={
                      errorState["first_name"] && errorState["first_name"].error
                    }
                    helperText={
                      errorState["first_name"] &&
                      errorState["first_name"].error &&
                      errorState["first_name"].errorMessage
                    }
                    FormHelperTextProps={{
                      sx: {
                        bgcolor: "background.paper",
                      },
                    }}
                    // inputProps={{
                    //   sx: {
                    //     textAlign: "right",
                    //     "&::placeholder": {
                    //       textAlign: "right",
                    //     },
                    //   },
                    //   maxLength: 30,
                    //   spellCheck: "false",
                    // }}
                    InputProps={{ sx: { borderRadius: "1.4rem" } }}
                    size="small"
                    sx={{ marginBottom: "1rem" }}
                  />
                </FormGroup>
              </Grid>
              <Grid item md={6} width={"100%"}>
                <FormGroup>
                  <Typography className={styles.lableText}>
                    Last Name*
                  </Typography>

                  <TextField
                    fullWidth
                    type="text"
                    name="firstname"
                    placeholder="Enter Last Name"
                    value={localState["last_name"]}
                    onChange={(e) => {
                      captureCredentials(e, "last_name");
                      validateString(e.target.value, "last_name");
                    }}
                    error={
                      errorState["last_name"] && errorState["last_name"].error
                    }
                    helperText={
                      errorState["last_name"] &&
                      errorState["last_name"].error &&
                      errorState["last_name"].errorMessage
                    }
                    FormHelperTextProps={{
                      sx: {
                        bgcolor: "background.paper",
                      },
                    }}
                    className={styles.loginInput}
                    InputProps={{ sx: { borderRadius: "1.4rem" } }}
                    size="small"
                    sx={{ marginBottom: "1rem" }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid item md={12} width={"100%"} display={"flex"} flexDirection={"row"}>
              <Grid item md={6} width={"100%"}>
                <FormGroup>
                  <Typography className={styles.lableText}>
                    Mobile Number*
                  </Typography>
                  <MuiPhoneNumber 
                  placeholder="Enter a Mobile No."
                  fullWidth
                  sx={{ flex: 1 }}
                  variant="outlined"
                  size="small"
                  error={
                    errorState["phone_number"] &&
                    errorState["phone_number"].error
                  }
                  helperText={
                    errorState["phone_number"] &&
                    errorState["phone_number"].error &&
                    errorState["phone_number"].errorMessage
                  }
                  FormHelperTextProps={{
                    sx: {
                      bgcolor: "background.paper",
                      m: 0,
                      px: 1,
                      pt: 0.5,
                    },
                  }}
                  inputClass={styles.loginInput}
                  disableAreaCodes={true}
                  defaultCountry={'ca'}
                  preferredCountries={['ca']}
                  value={localState["phone_number"]}
                  onChange={(value, country) => {
                    console.log(country);
                    captureFormFields({target: {value: value}}, "phone_number");
                    // captureFormFields({target: {value: country.dialCode}}, "country_code");
                    validateContactNo(value, "phone_number");
                  }}/>
                </FormGroup>
              </Grid>
              <Grid item md={6} width={"100%"}>
                <FormGroup>
                  <Typography className={styles.lableText}>
                    Email Id*
                  </Typography>

                  <TextField
                    fullWidth
                    type="text"
                    name="Email ID"
                    error={errorState["email"] && errorState["email"].error}
                    placeholder="Enter an Email ID"
                    className={styles.loginInput}
                    InputProps={{ sx: { borderRadius: "1.4rem" } }}
                    size="small"
                    sx={{ marginBottom: "1rem" }}
                    disabled={Boolean(userData)}
                    value={localState["email"]}
                    onChange={(e) => {
                      captureCredentials(e, "email");
                      validateEmail(e.target.value, "email");
                    }}
                    helperText={
                      errorState["email"] &&
                      errorState["email"].error &&
                      errorState["email"].errorMessage
                    }
                    FormHelperTextProps={{
                      sx: { bgcolor: "background.paper", m: 0, px: 1, pt: 0.5 },
                    }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid item md={12} width={"100%"} display={"flex"} flexDirection={"row"}>
              <Grid item md={6} width={"100%"}>
                <FormGroup>
                  <Typography className={styles.lableText}>Role*</Typography>

                  <TextField
                    fullWidth
                    className={styles.loginInput}
                    value={localState["role"]}
                    error={errorState["role"] && errorState["role"].error}
                    placeholder="Select a Role"
                    sx={{
                      "& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          minHeight: 0,

                          bgcolor: "#E9ECF2",
                          // color: "secondary.main",
                          // fontSize:'0.6rem'
                        },
                    }}
                    onChange={handleRoleChange}
                    variant="outlined"
                    InputProps={{
                      sx: { borderRadius: "1.4rem", padding: "0 0" },
                    }}
                    inputProps={{
                      sx: { padding: "0.6rem 0.9rem" },
                    }}
                    select
                    helperText={
                      errorState["role"] &&
                      errorState["role"].error &&
                      errorState["role"].errorMessage
                    }
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"ADMIN"}>Admin</MenuItem>
                    <MenuItem value={"PARTNER"}>Partner</MenuItem>
                  </TextField>
                </FormGroup>
              </Grid>
              <Grid item md={6} width={"100%"}>
                <FormGroup>
                  <Typography className={styles.lableText}>Vendor*</Typography>

                  <Autocomplete
                    id="country-select-demo"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "0.125rem",
                        paddingX: "0.5rem",
                        width: "100%",
                      },
                    }}
                    className={styles.loginInput}
                    open={vendorSelectOpen}
                    onOpen={handleVendorOpen}
                    onClose={handleVendorClose}
                    options={getDropDownList()}
                    value={selectedVendor}
                    onInputChange={(e) => handleVendorSearch(e)}
                    filterOptions={(x) => x}
                    onChange={(e, newValue) => handleVendorChange(e, newValue)}
                    ListboxProps={{ sx: { fontSize: "0.8rem" } }}
                    getOptionLabel={(option) => option.label}
                    
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                    loading={vendorLoading}
                    
                  />

                </FormGroup>
              </Grid>
            </Grid>
            <Grid item md={12} width={"100%"} display={"flex"} flexDirection={"row"}>
              <Grid item md={6} width={"100%"}>
                <Typography className={styles.lableText}>Password</Typography>
                <TextField
                  value={localState.password}
                  onChange={(e) => {
                    captureCredentials(e, "password");
                    passwordValidator(e.target.value, "password");
                  }}
                  id="password"
                  error={errorState["password"] && errorState["password"].error}
                  placeholder="Password"
                  // value={localPasswordState.new_password}
                  variant="outlined"
                  type={showNewPassword ? "text" : "password"}
                  fullWidth
                  disabled={localState.internal}
                  FormHelperTextProps={{
                    sx: {
                      bgcolor: "background.paper",
                      m: 0,
                      px: 1,
                      pt: 0.5,
                    },
                  }}
                  helperText={
                    errorState["password"] &&
                    errorState["password"].error &&
                    errorState["password"].errorMessage
                  }
                  
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          onMouseDown={() =>
                            setShowNewPassword(!showNewPassword)
                          }
                        >
                          {showNewPassword ? (
                            <VisibilityOffOutlinedIcon fontSize="small" />
                          ) : (
                            <VisibilityOutlinedIcon fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    // sx: { borderRadius: "1.4rem" },
                  }}
                  size="small"
                  className={styles.loginPasswordInput}
                />
              </Grid>
              <Grid item md={6} width={"100%"}>
                <FormGroup>
                  <Typography className={styles.lableText}>
                    Type Of User*
                  </Typography>
                  <FormControl
                    sx={{
                      marginTop: "0.6rem",
                    }}
                  >
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="internal"
                      name="radio-buttons-group"
                      value={localState.internal ? "internal" : "external"}
                      onChange={handleAuthTypeChange}
                      sx={{ paddingLeft: "0.8rem" }}
                    >
                      <FormControlLabel
                        value="internal"
                        control={<Radio />}
                        label="Internal"
                      />
                      <FormControlLabel
                        value="external"
                        control={<Radio />}
                        label="External"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl
                    sx={{
                      marginTop: "0.2rem",
                      marginLeft: "0.8rem",
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel control={
                        <Checkbox
                        checked={localState.looker_access}
                        onChange={() => {
                          setLocalState((prevState) => ({
                            ...prevState,
                            looker_access: !prevState.looker_access,
                          }));
                          setUserTouchedStatus(true);
                        }}/>} 
                        inputProps={{ 'aria-label': 'controlled' }}
                        label="Looker Access" />
                    </FormGroup>
                  </FormControl>
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            my: 1,
            mx: 5,
          }}
        >
          <Button
            className={styles.normalBtncls}
            onClick={handleClose}
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            className={styles.buttoncls}
            onClick={userData ? editUser : addUser}
            disabled={
              hasUserTouched &&
              localState.first_name &&
              localState.last_name &&
              localState.email &&
              localState.phone_number &&
              localState.role &&
              localState.vendor_id &&
              localState.internal != null &&
              localState.looker_access != null &&
              !getErrorState()
                ? false
                : true
            }
          >
            {userData ? "Update User" : "Add User"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
