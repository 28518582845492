import React from "react";
import Table from "@mui/material/Table";
import moment from "moment";
import { FormHelperText, InputAdornment } from "@mui/material";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
// import TableContainer from "@mui/material/TableContainer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useEffect, useState, useMemo } from "react";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import Paper from "@mui/material/Paper";
import {
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  TableHead,
  TableRow,
  Skeleton,
  Typography,
  Grid,
  TablePagination,
  Avatar,
  Box,
  Button,
} from "@mui/material";
import styles from "./usertable.module.scss";
import services from "../../../services/services";
import { styled, useTheme } from "@mui/material/styles";
import SortIcon from "@mui/icons-material/Sort";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
// import EditUser from "../edit-user/edit-user";
import CreateUser from "../../create-user/CreateUser"
import DeleteUser from "../delete-user/delete-user";
import userServices from "../../../services/userServices";
const useStyles = styled((theme) => ({
  tableRow: {
    backgroundColor: "#fff !important",
  },
  selectedRow: {
    backgroundColor: `#E1E4EE !important`,
    "& .MuiTableCell-root": {
      color: "black",
    },
  },
}));

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
export default function UserTable(props) {
  const { dateValues, resetFilter, refreshTable } = props;

  const [userData, setUserData] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const { items, requestSort, sortConfig } = useSortableData(userData);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showChildComponent, setShowChildComponent] = useState(false);
  
  const [isEditSuccess, setEditSuccess] = useState(false);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    getAllUsers();
  }, []);
  const getFilteredData = () => {
    var startDate = new Date(dateValues[0]+'T00:00:00.000Z');
    var endDate = new Date(dateValues[1]+'T23:59:59.000Z');

    var resultProductData = allUsers.filter((a) => {
      var date = new Date(a.created_at);
      return date >= startDate && date <= endDate;
    });
    setUserData(resultProductData);
  };

  useEffect(() => {
    if (isEditSuccess || refreshTable) {
      getAllUsers();
    }
  }, [isEditSuccess, refreshTable]);

  useEffect(() => {
    if (dateValues && dateValues.length > 0) {
      getFilteredData();
    } else {
      setUserData(allUsers);
    }
  }, [dateValues]);

  useEffect(() => {
    if (selectedUser) {
      setShowChildComponent(true);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (resetFilter) {
      setUserData(allUsers);
    }
  }, [resetFilter]);

  const classes = useStyles({});

  const [openEditUser, setOpenEditUser] = useState(false);
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const getAllUsers = async () => {
    userServices
      .getAllUser()
      .then((response) => {
        if (response) {
          // console.log("getAllUser", response);
          setAllUsers(response);
          setUserData(response);
          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);

        console.log("catch if", e);
      });
  };

  const closeModal = () => {
    setSelectedUser(null);
    setOpenEditUser(false);
    setOpenDeleteUser(false);
    setShowChildComponent(false);
  };
  
  const getFilterIcon = (selectedKey) => {
    return sortConfig &&
      sortConfig.key === selectedKey &&
      sortConfig.direction ? (
      sortConfig && sortConfig.direction === "ascending" ? (
        <ArrowUpwardOutlinedIcon
          className={styles.filterIcon}
        ></ArrowUpwardOutlinedIcon>
      ) : (
        <ArrowDownwardOutlinedIcon
          className={styles.filterIcon}
        ></ArrowDownwardOutlinedIcon>
      )
    ) : (
      <SortIcon className={styles.filterIcon}></SortIcon>
    );
  };

  const deleteData = (deleteData) => {

    if(deleteData) {
      let temp = JSON.parse(JSON.stringify(allUsers))
      let result = temp.findIndex((item)=> item.id === selectedUser.id)
      temp.splice(result, 1);
      setAllUsers(temp)
      setUserData(temp);
    }
    setSelectedUser(null);
  }

  return (
    <Grid md={12} padding={2}>
      {/* <ProductTable products={userData}></ProductTable> */}
      {loader ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          style={{ height: "396px", width: "100%" }}
          sx={{ bgcolor: "grey.150" }}
        ></Skeleton>
      ) : items && items.length > 0 ? (
        <div>
          <TableContainer
            component={Paper}
            style={{ alignSelf: "center", marginBottom: "1rem" }}
          >
            <Table className={styles.table} aria-label="simple table">
              <TableHead className={styles.headerclass}>
                <TableRow>
                  <TableCell>
                    <Typography
                      className={styles.headerText}
                      onClick={() => requestSort("first_name")}
                    >
                      User Name
                      {getFilterIcon("first_name")}
                      {/* {sortConfig && sortConfig.direction ? (
                        sortConfig && sortConfig.direction === "ascending" ? (
                          <ArrowUpwardOutlinedIcon
                            className={styles.filterIcon}
                          ></ArrowUpwardOutlinedIcon>
                        ) : (
                          <ArrowDownwardOutlinedIcon
                            className={styles.filterIcon}
                          ></ArrowDownwardOutlinedIcon>
                        )
                      ) : (
                        <SortIcon className={styles.filterIcon}></SortIcon>
                      )} */}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={styles.headerText}
                      onClick={() => requestSort("last_login_time")}
                    >
                      Last Login{getFilterIcon("last_login_time")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={styles.headerText}
                      onClick={() => requestSort("last_active")}
                    >
                      Time Since Last Login{getFilterIcon("last_active")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={styles.headerText}
                      onClick={() => requestSort("created_at")}
                    >
                      Date Created{getFilterIcon("created_at")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={styles.headerText}
                      onClick={() => requestSort("organization")}
                    >
                      Organization{getFilterIcon("organization")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={styles.headerText}>
                      Actions
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {items ? (
                  items.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        background: "white",
                        padding: 0,
                      }}
                      classes={{
                        root:
                          index === selectedRow
                            ? classes.selectedRow
                            : classes.tableRow,
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          alignItems={"center"}
                        >
                          <Avatar
                            sx={{
                              height: "1.5rem",
                              width: "1.5rem",
                              marginRight: "0.2rem",
                            }}
                          >
                            <AccountCircleIcon sx={{ fontSize: "1.5rem" }} />
                          </Avatar>
                          <Typography className={styles.rowText}>
                            {row.first_name} {row.last_name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography className={styles.rowText}>
                          {row.last_login_time
                            ? moment(row.last_login_time).format("DD-MM-YYYY")
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography className={styles.rowText}>
                          {row.last_active ? row.last_active : ""}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={styles.rowText}>
                          {row.created_at
                            ? moment(row.created_at).format("DD-MM-YYYY")
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography className={styles.rowText}>
                          {row.vendor ? 
                                      row.country ? `${row.vendor} - ${row.country}` 
                                                  : `${row.vendor}`
                                      : ""}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          // onClick={(e) => console.log("clicked")}
                          onClick={() => {
                            // if (!anchorEl) {
                            setSelectedUser(row);
                            setOpenEditUser(true);
                            // }
                          }}
                        >
                          <EditOutlinedIcon style={{ fontSize: "1.2rem" }} />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => {
                            // if (!anchorEl) {
                            setSelectedUser(row);
                            setOpenDeleteUser(true);
                            // }
                          }}
                        >
                          <DeleteOutlineOutlinedIcon
                            style={{ fontSize: "1.2rem" }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={{ height: "396px", width: "100%" }}
                        sx={{ bgcolor: "grey.150" }}
                      ></Skeleton>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {/* <TablePagination
          sx={{
            "& .MuiTablePagination-toolbar": {
              minHeight: 0,
              px: 2,
              py: 1,
              bgcolor: "#E9ECF2",
              // color: "secondary.main",
              // fontSize:'0.6rem'
            },
          }}
          rowsPerPageOptions={[5, 10, 15, 20]}
          colSpan={8}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          labelDisplayedRows={({
            from,
            to,
            count,
          }) => `Showing Results of ${from}–${to} of ${
            count !== -1 ? count : `more than ${to}`
          }
                `}
          // onPageChange={handleChangePage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
          // ActionsComponent={QTablePaginationActions}
          component="div"
        /> */}
          </TableContainer>

          {showChildComponent && selectedUser && (
            // <EditUser
            //   openModal={openEditUser}
            //   closeModal={() => closeModal()}
            //   userData={selectedUser}
            //   setEditSuccess={(value) => {
            //     setEditSuccess(value);
            //   }}
            // />
            <CreateUser
              openModal={openEditUser}
              closeModal={closeModal}
              userData={selectedUser}
              setEditSuccess={(value) => {
                setEditSuccess(value);
              }}
            ></CreateUser>
          )}
          {showChildComponent && selectedUser && (
            <DeleteUser
              openModal={openDeleteUser}
              closeModal={closeModal}
              userData={selectedUser}
              deleteData={deleteData}
            />
          )}
        </div>
      ) : undefined}
    </Grid>
  );
}
